import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import BlogListItem from '../components/BlogListItem'
import Bio from '../components/Bio'
import Layout from '../components/Layout'
import BlogTitle from '../components/blogTitle'

function IndexPage({ data, pageContext, location }) {
  const { subtitle, name } = pageContext
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const posts = data.allMdx.edges
  return (
    <Layout
      location={location}
      title={<BlogTitle title={siteTitle} subtitle={name} />}
      subtitle={subtitle}
    >
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        meta={[{ name: 'description', content: siteDescription }]}
        title={`${siteTitle} | ${subtitle}`}
      />
      {posts.map((p, i) => (
        <BlogListItem key={i} node={p.node} />
      ))}
      <Bio />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery($tag: String) {
    ...MetadataFragment

    allSitePage {
      edges {
        node {
          id
          path
          pageContext
        }
      }
    }
    allMdx(
      filter: { frontmatter: { type: { eq: $tag } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      ...MdxIndexFragment
    }
  }
`
