import React from 'react'
import colors from '../components/color'

const BlogTitle = ({ title, subtitle }) => (
  <div>
    {title} | <span style={{ color: colors.indexPageTitle }}>{subtitle}</span>
  </div>
)

export default BlogTitle
